import MJ from './MJ'
import VEG from './VEG'

export default {
  'Accept terms': 'Accept',
  'Country explanation': 'To provide you with the correct user agreement please select the location of your company.',
  'Select country': 'Company location',
  content: {
    MJ,
    VEG,
  },
}
